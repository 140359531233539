<!-- 简历详情页面 -->
<template>
  <div class="box">
    <div id="codeList" class="wrap" v-loading="loading">
      <div class="get-btn-wrap" id="no-print">
        <div class="get-btn">
          <el-dropdown
            v-if="typeof data.status === 'number'"
            split-button
            type="text"
            @command="commandHandle"
            :style="{ backgroundColor: dropdownMenu[data.status].color }"
          >
            {{ dropdownMenu[data.status].type }}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="index"
                v-for="(item, index) in dropdownMenu.slice(1)"
                :key="index"
                >{{ item.type }}</el-dropdown-item
              >
              <!-- <el-dropdown-item command="复试">复试</el-dropdown-item>
              <el-dropdown-item command="发offcr">发offcr</el-dropdown-item>
              <el-dropdown-item command="不合格">不合格</el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="comment" @click="centerDialogVisible = true">沟通备注</div>

        <div class="comment" v-if="data.elite_id" @click="onChatDetail">
          聊一聊
        </div>
        <div class="comment print" @click="goPrint">打印</div>
      </div>

      <!-- 基本信息 -->
      <div style="overflow: hidden">
        <div class="img-wrap">
          <img
            src="./../../assets/images/test/boy.png"
            alt
            v-if="data.basic_gender === '男'"
          />
          <img src="./../../assets/images/test/girl.png" alt v-else />
        </div>

        <div class="all-base">
          <div class="name">{{ data.basic_name }}</div>
          <div class="base" style="width: 300px">
            <span>{{ data.basic_gender }}</span>
            <i></i>
            <span>{{ data.basic_age }}岁</span>
            <i></i>
            <span v-if="data.resume_education_experience_list">{{
              data.resume_education_experience_list[0].education_background
            }}</span>
            <span></span>
            <i></i>
            <span>{{ data.basic_exp_year }}年</span>
          </div>
          <!-- <div class="tag">
            <span>沟通力强</span>
            <span>执行力强</span>

            <span>学习力强</span>

            <span>诚信正直</span>
          </div>-->
          <div class="expect">
            <!-- <p>
              求职状态：
              <span>求职中</span>
            </p>-->
            <p>
              期望职位：
              <span style="margin-right: 7px">{{
                data.expect_position_name_list
                  ? data.expect_position_name_list.join(" , ")
                  : ""
              }}</span>

              <!-- <span
                style="margin-right:7px"
                v-for="(item,index) in data.expect_position_name_list"
                :key="index"
              >{{item}}</span> -->
            </p>
            <p>
              期望地点：
              <span
                style="margin-right: 7px"
                v-for="(item, index) in data.expect_position_place_list"
                :key="index"
                >{{ item }}</span
              >
            </p>
            <p
              v-if="
                data.resume_expect_position_list &&
                data.resume_expect_position_list.length > 0
              "
            >
              期望薪资：
              <span
                >{{ data.resume_expect_position_list[0].salary_min }}-{{
                  data.resume_expect_position_list[0].salary_max
                }}元</span
              >
            </p>
            <p v-if="data.basic_phone != ''">
              联系方式：
              <span>{{ data.basic_phone }}</span>
            </p>

            <p style="color: #ff001b" v-if="data.state_index > 0">
              求职状态：{{ data.state_str }}
            </p>
          </div>
        </div>
      </div>
      <!-- 工作经历 -->
      <div
        class="work"
        v-if="
          data.resume_work_experience_list &&
          data.resume_work_experience_list.length > 0
        "
      >
        <div class="title">工作经历</div>
        <div
          v-for="(item, index) in data.resume_work_experience_list"
          :key="index"
        >
          <p class="company-name" v-if="item.company_name != ''">
            {{ item.company_name }}
          </p>
          <p v-if="item.end_month != 0">
            工作时间：
            <span
              >{{ item.start_year }}年{{ item.start_month }}月-{{
                item.end_year
              }}年{{ item.end_month }}月</span
            >
          </p>
          <p v-if="item.upper != 0">
            薪资水平：
            <span>{{ item.floor }}-{{ item.upper }}</span>
          </p>

          <p v-if="item.position_name != ''">
            在职职位：
            <span>{{ item.position_name }}</span>
          </p>
        </div>

        <!-- 
        -->
      </div>
      <!-- 教育经历 -->

      <div
        class="edu"
        v-if="
          data.resume_education_experience_list &&
          data.resume_education_experience_list.length > 0
        "
      >
        <div v-if="data.resume_education_experience_list[0].school != ''">
          <div class="title">教育经历</div>
          <p
            v-for="(item, index) in data.resume_education_experience_list"
            :key="index"
          >
            <span v-if="item.school != ''">{{ item.school }}</span>
            <span v-if="item.end_month != 0">
              <i></i>
              {{ item.start_year }}年{{ item.start_month }}月-{{
                item.end_year
              }}年{{ item.end_month }}月
            </span>
            <span v-if="item.major != ''">
              <i></i>
              {{ item.major }}
            </span>
          </p>
        </div>
      </div>
      <!-- 自我评价 -->
      <div class="self" v-if="!loading">
        <div
          v-if="data.basic_self_evaluation && data.basic_self_evaluation != ''"
        >
          <div class="title">自我评价</div>
          <div class="self-word">{{ data.basic_self_evaluation }}</div>
        </div>
      </div>
      <!-- 面试备注 -->
      <div class="interview-comment" v-if="data.remarks">
        <div class="title">沟通备注</div>
        <div class="self-word">
          <!-- 本人性格开朗稳重有活力，待人热情真诚。积极主动，能持股耐劳；虚心与人交流，以取长补短。有较强的实际动手能力和团队协作精神，能迅速的适应各种环境，并融合其中。在曾经的工作期间满足客户需求，虚心向客户介绍产品，不急不躁；社会责任感强，踏实肯干，主动争取锻炼机会。不断地完善自我，提高自身素质。能熟练运用电脑，掌握一定的office办公软件，本着多学多做的心理，对工作认真负责，学以致用。-->
          {{ data.remarks }}
        </div>
      </div>
    </div>

    <el-dialog
      title="沟通备注"
      :visible.sync="centerDialogVisible"
      width="500px"
      center
    >
      <textarea class="area" v-model="str"></textarea>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ResumeRemark">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="面试时间"
      :visible.sync="datetimeDialogVisible"
      width="500px"
      center
    >
      <el-date-picker
        v-model="cdatetime"
        type="datetime"
        placeholder="选择时间"
        value-format="yyyy-MM-dd HH:mm"
        format="yyyy-MM-dd HH:mm"
      ></el-date-picker>

      <span slot="footer" class="dialog-footer">
        <el-button @click="datetimeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="c_datetime">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getResumeBankDetailsData } from "@/api/resume";
import { getResumeRemark, getResumeStatus, set_cdatetime } from "@/api/tool";
import print from "print-js";

export default {
  //import引入的组件需要注入到对象中才能使用

  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      id: "",
      data: {},
      //获取简历
      resumeGet: false,
      //标记
      signShow: false,
      // 下拉菜单
      // command: '未标记',
      //下拉菜单背景色
      dropdownBgc: ["primary", "success", "warning", "danger", "info"],
      //下拉菜单背景色下标
      dropdownBgcIndex: 0,
      dropdownMenu: [
        { color: "#ccc", type: "未标记" },
        { color: "#F56C6C", type: "不合格" },
        { color: "#E6A23C", type: "未接通" },
        { color: "#67C23A", type: "可面试" },
      ],
      centerDialogVisible: false,
      datetimeDialogVisible: false,
      str: "",
      cdatetime: "",
      name: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //打印
    goPrint() {
      printJS({
        printable: "codeList",
        type: "html",
        targetStyles: ["*"],
        scanStyles: true,
        ignoreElements: ["no-print"],
      });
    },

    // 进入聊天详情页面
    onChatDetail() {
      //获取数据跳转到聊天页面
      console.log();
      var elite_id = this.data.elite_id;
      this.$router.push({
        path: "/chatdetail",
        query: {
          elite_id: elite_id,
          jd_id: 0,
        },
      });
    },

    //下拉菜单
    commandHandle(command) {
      if (command == 2) {
        this.datetimeDialogVisible = true;
      } else {
        console.log(command);
        let data = {
          resume_id: this.id,
          status: command + 1,
        };

        console.log("设置状态");

        getResumeStatus(data).then((res) => {
          if (res.code === 0) {
            this.getData();
          }
        });
      }
    },

    //面试时间
    c_datetime() {
      this.datetimeDialogVisible = false;
      let data = {
        resume_id: this.id,
        name: this.name,
        cdatetime: this.cdatetime,
      };

      if (this.cdatetime == "") {
        this.$message({
          message: "请选择面试时间！",
          type: "error",
        });
        return;
      }

      console.log("选择的时间：" + this.cdatetime);

      set_cdatetime(data).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.getData();

          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });

      let data2 = {
        resume_id: this.id,
        status: 3,
      };

      console.log("设置状态");

      getResumeStatus(data2).then((res) => {
        if (res.code === 0) {
          this.getData();
        }
      });
    },

    //面试备注
    ResumeRemark() {
      this.centerDialogVisible = false;
      let data = {
        resume_id: this.id,
        remarks: this.str,
      };

      getResumeRemark(data).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.getData();

          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    getData() {
      getResumeBankDetailsData({ resume_id: this.id }).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.data = res.data;
          this.loading = false;
          this.str = res.data.remarks;
          this.name = res.data.basic_name;
        }
      });
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style  scoped lang="scss">
/* //@import url(); 引入公共css类 */

.box {
  @include padding;
  min-width: 1100px;
  min-height: 70vh;
  padding-bottom: 20px;
}
.wrap {
  padding: 20px 50px;
  min-height: 80vh;

  width: 1000px;
  // overflow: auto;
  // height: calc(90vh - 100px);
  margin: 0 auto;
  background-color: #fff;
  border-radius: $radius;
  box-shadow: $box-shadow;
}
.get-btn-wrap {
  width: 100%;
  height: 50px;
}
.get-btn {
  float: right;
}

.get-btn::after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}

/* 头像 */
.img-wrap {
  width: 110px;
  height: 110px;
  float: left;
  text-align: center;
}
.img-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.all-base {
  float: left;
  margin-left: 120px;
}
.name {
  font-size: 28px;
  color: $jd_fontColor;
  margin-bottom: 10px;
}
.base {
  font-size: $jd_fontSize;
  color: $selfColor;
}
/* 基本信息间隔 */
i {
  margin: 0 15px;
  border-right: 1px solid #ccc;
}
// 标签

.tag span {
  width: 80px;
  height: 30px;
  display: inline-block;
  margin-top: 20px;
  margin-left: 10px;
  text-align: center;
  line-height: 30px;
  background-color: $main_bc;
  font-size: $jd_fontSize;
  color: $main_color;
}
// 期望
.expect p {
  font-size: $jd_fontSize;
  color: $jd_fontColor;
}
.expect p span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.title {
  color: #000;
  font-size: 20px;
  margin: 20px;
  font-weight: bold;
}
.work,
.edu,
.self {
  margin-top: 39px;
}
.work {
  clear: both;
}
.company-name {
  font-size: 18px;
  color: $jd_fontColor;
  padding-left: 10px !important;
}
.work p {
  font-size: $jd_fontSize;
  color: $jd_fontColor;
  padding: 0 30px;
  margin: 10px 0 0 0;
}
.work span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.edu p {
  padding: 0 30px;
}
.edu p span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.self .self-word {
  font-size: $jd_fontSize;
  color: $selfColor;
  // background: red;
  padding: 0px 30px;
}

.interview-comment .self-word {
  font-size: $jd_fontSize;
  color: $selfColor;
  // background: red;
  padding: 0px 30px;
}
.area {
  border: 1px solid #ff9900;
  width: 450px;
  height: 100px;
  min-width: 450px;
  max-width: 450px;
  font-size: 16px;
  color: $selfColor;
  // text-align: center;
  // margin: 0 auto;
  display: inline-block;
  font-family: auto;
}
.area:focus {
  outline: none !important;
  border: 1px solid #719ece;
  box-shadow: 0 0 10px #719ece;
}

.print {
  float: right;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #6d9e1e;
  cursor: pointer;
  padding: 0 10px;
  margin-right: 10px;
}

/*去除页眉页脚*/
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}

html {
  background-color: #ffffff;
  margin: 0; /* this affects the margin on the html before sending to printer */
}

body {
  border: solid 1px blue;
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
}
/*去除页眉页脚*/
</style>